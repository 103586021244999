export const XLABEL = {
    axisLabel: {
        rotate: 25,
        color: '#999999',
        margin: 22,
        fontSize: 12,
        formatter: function (params) {
            let newParamsName = ''
            let paramsNameNumber = params.length
            //超过5个字符折行
            let provideNumber = 5;
            let rowNumber = Math.ceil(paramsNameNumber / provideNumber)
            for (let row = 0; row < rowNumber; row++) {
                newParamsName +=
                    params.substring(
                        row * provideNumber,
                        (row + 1) * provideNumber
                    ) + '\n'
            }
            return newParamsName
        }
    },
    axisLine: {
        show: false,
        lineStyle: {
            color: '#999999'
        }
    },
    axisTick: {
        alignWithLabel: true
    }
}
