<template>
  <div class="mod-demo-echarts">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card>
          <div :id="'org_chartBarBox_'+type" class="chart-box"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {XLABEL} from "@/config/echats.option";

export default {
  props: {
    type: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      chartBar: null,
      serveInfo: []
    }
  },
  mounted() {
    this.getByType()
  },
  activated() {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartBar) {
      this.chartBar.resize()
    }
  },
  methods: {
    getByType() {
      const that = this;
      this.$nextTick(() => {
        that.$http({
          url: that.$http.adornUrl('/statistics/by-org?type=' + that.type),
          method: 'get',
        }).then(({data}) => {
          that.serveInfo = data.data;
          that.initChartBar()
        })
      })
    },
    // 柱状图
    initChartBar() {
      const infos = JSON.parse(JSON.stringify(this.serveInfo));
      infos.sort((a, b) => {
        return b.serveObjNum - a.serveObjNum;
      })
      const names = infos.map(info => info.oname ? info.oname : '其它');
      const serveNumVals = infos.map(info => info.serveNum);
      const serveUserVals = infos.map(info => info.serveUser);
      const serveObjNumVals = infos.map(info => info.serveObjNum);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['服务次数', '服务人次', '服务用户数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: names,
            ...XLABEL
          }
        ],
        yAxis: [
          {
            type: 'log'
          }
        ],
        series: [
          {
            name: '服务次数',
            type: 'bar',
            data: serveNumVals
          },
          {
            name: '服务人次',
            type: 'bar',
            data: serveObjNumVals
          },
          {
            name: '服务用户数',
            type: 'bar',
            data: serveUserVals
          },
        ]
      }
      this.chartBar = echarts.init(document.getElementById('org_chartBarBox_' + this.type))
      this.chartBar.setOption(option)
      window.addEventListener('resize', () => {
        this.chartBar.resize()
      })
    },
  }
}
</script>

<style lang="scss">
.mod-demo-echarts {
  > .el-alert {
    margin-bottom: 10px;
  }

  > .el-row {
    margin-top: -10px;
    margin-bottom: -10px;

    .el-col {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .chart-box {
    min-height: 400px;
  }
}
</style>
